/* You can add global styles to this file, and also import other style files */
@import "theme/fonts/_fonts.scss";

* {
  box-sizing: border-box;
}

html, body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

.ais-SearchBox { margin-bottom: 1em }


// Removing scrollbars
::-webkit-scrollbar {
  width: 0;  /* remove scrollbar space */
  background: transparent;  /* optional: just make scrollbar invisible */
}
