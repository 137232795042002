@import '../mixins/_mixins';

@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,300;0,400;0,600;0,700;1,400&family=Vollkorn:wght@400;500&display=swap');

h1, h2, h3, h4 {
  font-family: 'Vollkorn', serif;
  color: #1a1919;
}

h1 {
  font-size: 2.8rem;
  line-height: 1.65;
  letter-spacing: normal;
  margin: 0;
  padding: 0;
}

h2 {
  font-size: 2.5rem;
  line-height: 1.65;
  letter-spacing: normal;
  margin: 0;
  padding: 0;
}

h3 {
  font-size: 2rem;
  line-height: 1.65;
  letter-spacing: normal;
  margin: 0;
  padding: 0;
}

h4 {
  font-size: 1.5rem;
  line-height: 1.65;
  letter-spacing: normal;
  margin: 0;
  padding: 0;
}

p {
  font-size: 1.1rem;
  color: #383a3f;
  font-family: 'Raleway', sans-serif;
  line-height: 1.65;
  letter-spacing: 2px;

  & .b {
    font-weight: bold;
  }

  & .i {
    font-style: italic;
  }
}

a {
  font-family: 'Raleway', sans-serif;
  transition: all .3s ease-in-out;
  cursor: pointer;

  @include hocus {
    color: darken(#eec053, 10) !important;
    text-decoration: underline;
  }
}
